import React from 'react'

let vector = [0]

const Proved = function proved ({}){
    return (
        <section className="proved">
            <div className="container colflex">
                <img src="/img/imagen_metodo.png" alt="proved-text"/>
                <img src="/img/foto_empresarios.jpg" alt="proved-img"/>
            </div>
        </section>
    )
}

export default Proved