import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";

let vector = [0]

const Form = function form ({
        text,
        onChange,form,submit,recaptchaRef
    }){
    return (
        <section className="form">
            <div className="container colflex" id="formDiv">
                <h3 dangerouslySetInnerHTML={{__html: text.text}}></h3>
                <p dangerouslySetInnerHTML={{__html: text.title}}></p>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey="6LeAu3cgAAAAAIhj6gFouDEtkkynaMvDRUtSJGM1"
                />
                <a className="flex btn" href='https://buy.stripe.com/eVabK423v5oV86YfYY'>
                    <span>Ir a esta oferta</span>
                    <img src="/img/arrowb.svg" alt="➡️"/>
                </a>
                <form className="colflex" id="formContact" style={{display:"none"}}>
                    <input 
                        type="text" 
                        placeholder="Nombre" 
                        value={form.name}
                        onChange={onChange}
                        name="name"
                    />
                    <input 
                        type="tel" 
                        placeholder="Teléfono"
                        value={form.phone}
                        onChange={onChange}
                        name="phone"
                    />
                    <input 
                        type="email" 
                        placeholder="Correo"
                        value={form.mail}
                        onChange={onChange}
                        name="mail"
                    />
                    <input 
                        type="text" 
                        placeholder="Ciudad"
                        value={form.city}
                        onChange={onChange}
                        name="city"
                    />
                    <textarea 
                        rows="6"
                        placeholder="Mensaje..."
                        name="messague"
                        value={form.messague}
                        onChange={onChange}
                    ></textarea>
                    <div className="policy colflex">
                        <p>Al enviar este mensaje autorizo <a href={"/terms"}>el uso y tratamiento de datos.</a></p>
                        <a 
                            className="btnFormContacto" 
                            onClick={()=>submit()}
                        >Enviar</a>
                    </div>
                    <span>“{text.subtitle}"</span>
                </form>
            </div>
        </section>
    )
}

export default Form